@import './keyframes.scss';

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height      : 100vh;
  display         : flex;
  flex-direction  : column;
  align-items     : center;
  justify-content : center;
  font-size       : calc(10px + 2vmin);
  color           : white;
}

.App-link {
  color: #61dafb;
}

.sub-page-header {
  display        : flex;
  justify-content: center;
  padding        : 1rem;
  color          : var(--panda-primary-dark);
  user-select    : none;
  font-weight    : 600;
  font-size      : 1.2rem;
}

thead {
  background-color: var(--panda-primary-main);
  box-shadow      : 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  th {
    color: var(--panda-primary-contrastText) !important;
  }
}

.color-danger {
  color: brown !important;
}

.flex-column {
  display       : flex;
  flex-direction: column;
}